import infra from "@frontend/allspark/infra/index_beriberu-anketa";

const $infra = infra.infra;
let juicyScoreSessionId = null;

export default {
  state: {
    init: false,
    loan_application: null,
    default_promocode_conditions: [],
    addincomeData: null,
    rates: {
      term: {},
      amount: {},
    },
    incomeDictionary: [],
    historyStatus: [],
    client_documents: null,
  },
  getters: {
    applicationAddIncomeData({ addincomeData }) {
      return addincomeData || {};
    },
    applicationData({ loan_application }) {
      return loan_application || {};
    },
    applicationRates({ rates }) {
      return rates || {};
    },
    clientDocuments({ client_documents }) {
      return client_documents || {};
    },
  },
  mutations: {
    update(state, data) {
      state.init = true;
      state.loan_application = data || {};

      const { status } = state.loan_application;

      if (state.historyStatus.indexOf(status) === -1) {
        state.historyStatus.push(status);
      }
    },
    updateTermAndAmount(state, data) {
      if (!state.loan_application) {
        state.loan_application = {
          ...data,
        };
      } else {
        state.loan_application = {
          ...state.loan_application,
          ...data,
        };
      }
    },
    updateRates(state, data) {
      const isFakeTerm = false;

      if (isFakeTerm && data.term) {
        data.term = {
          default: 5,
          max: 7,
          min: 5,
        };
      }

      state.rates = data;
    },
    updatePromocodeConditions(state, payload) {
      state.default_promocode_conditions = payload;
    },
    updateClientDocuments(state, payload) {
      state.client_documents = payload;
    },
    updateAddIncomeData(state, payload) {
      state.addincomeData = payload;
    },
  },
  actions: {
    updateTermAndAmount({ state, commit }, data) {
      if (isNaN(+data.term) || isNaN(+data.amount)) {
        return;
      }

      if (
        !(state.loan_application.borrower && state.loan_application.borrower.id)
      ) {
        commit("updateTermAndAmount", data);
        return;
      }

      const { rates } = state;

      if (data.term > rates.term.max) {
        data.term = rates.term.max;
      } else if (data.term < rates.term.min) {
        data.term = rates.term.min;
      }

      if (data.amount > rates.amount.max) {
        data.amount = rates.amount.max;
      } else if (data.amount < rates.amount.min) {
        data.amount = rates.amount.min;
      }

      $infra.jsonrpc
        .updateLoanApplication({
          productParameters: {
            term: data.term,
            amount: data.amount,
          },
        })
        .then((res) => {
          commit("updateTermAndAmount", data);
          return res;
        })
        .catch((err) => {
          console.error(err);
          throw err;
        });
    },
    // eslint-disable-next-line  sonarjs/cognitive-complexity
    async getStatus({ commit, dispatch }) {
      let cpa = null;

      if (!window.iscpa && window.URLSearchParams) {
        const urlParams = new URLSearchParams(window.location.search);

        if (urlParams.get("cpa") == 1) {
          cpa = {};
          const entries = urlParams.entries();

          for (const [key, value] of entries) {
            cpa[key] = value;
          }
        } else {
          cpa = [];
        }

        window.iscpa = 1;
      }

      let loan = {};

      try {
        const response = await $infra.jsonrpc.getStatus(cpa);
        const addincomeData = response.addincomeData || {};

        loan = response.loan_application || {};

        if (loan.status === "approved" && loan.type === "prolongation") {
          // eslint-disable-next-line no-undef
          window.location = env.VUE_APP_LK_URL + "?type=prolongation";
        }

        if (loan.status === "canceled") {
          // eslint-disable-next-line no-undef
          window.location = env.VUE_APP_LK_URL;
        }

        const { rates, default_promocode_conditions, client_documents } =
          response;

        commit("update", loan);
        commit("updateRates", rates);
        commit("updateAddIncomeData", addincomeData);
        commit("updatePromocodeConditions", default_promocode_conditions);
        commit("updateClientDocuments", client_documents);
      } catch (e) {
        // @TODO нужно решение лучше
        //console.error(e);
        await dispatch("getRates");
      }

      // juicyScoreSessionId должна собираться только один раз
      if (!juicyScoreSessionId) {
        try {
          // eslint-disable-next-line promise/catch-or-return
          $infra.jsonrpc
            .juicyScoreStore(loan.id, window.juicyScoreApi)
            .then((sessionId) => {
              juicyScoreSessionId = sessionId;
              return sessionId;
            });
        } catch (e) {
          console.error("что то не так с juicyScoreStore ", e);
        }
      }
    },
    async getRates({ commit }) {
      try {
        const rates = await $infra.jsonrpc.getRates();

        commit("updateRates", rates);
      } catch (e) {
        console.error(e);
        // @TODO нужно решение лучше
        commit("update", {});
      }
    },
    async getStatusAndCheckRoute({ dispatch, commit }) {
      await dispatch("getStatus");
      commit("updateRoute", null, { root: true });
    },
  },
  namespaced: true,
};
